import React from "react";
import PublicLayout from "../layouts/PublicLayout";
import HomeSlider from "../components/Slider/HomeSlider";
import ProductsSlider from "../components/Slider/ProductsSlider";
import { Helmet } from "react-helmet";
import MobileSlider from "../components/Slider/MobileSlider";

const Home = () => {
  return (
    <PublicLayout>
      <Helmet>
        <title>Anasayfa</title>
      </Helmet>
      <div className="w-screen overflow-hidden">
        <div className="hidden md:block">
          <HomeSlider />{" "}
        </div>
        <div className="md:hidden ">
          <MobileSlider />
        </div>
        <ProductsSlider />
      </div>
      <div style={{textAlign: "center", margin: "40px 0"}}>
        <img src={window.location.origin + '/assets/img/european-union-logo.jpg'} style={{margin: "auto", width: "400px"}} />
        <img src={window.location.origin + '/assets/img/kayist.png'} style={{margin: "auto", width: "100px"}} />
        <p style={{margin: "15px 0 15px 0", fontSize: "14px"}}>Kayıtlı İstihdam Yaratma Projesi (Kayist) Şikayet ve Geri Bildirim Mekanizması</p>
        <a href="/kayist" style={{
             backgroundColor: "#2f3d57",
             color: "#fff",
             padding: "8px 0",
             borderRadius: "60px",
             width: "130px",
             display: "inline-block",
             fontWeight: "bold",
             fontSize: "12px",
           }}>
          DETAYLI BİLGİ
        </a>
    </div>
    </PublicLayout>
  );
};

export default Home;
