import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { API_BASE_URL } from "../../config/config";
import jsonTr from "../../translations/tr/common.json";
import jsonEn from "../../translations/en/common.json";
import { useLangContext } from "../../context/LangContext";

function KayistForm() {
  const initialFormData = {
    firstName: "",
    phoneNumber: "",
    email: "",
    message: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [formErrors, setFormErrors] = useState({});
  const { lang } = useLangContext();

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    if (type === "checkbox") {
      setFormData({
        ...formData,
        [name]: checked,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const errors = {};
    for (const field in formData) {
      if (!formData[field]) {
        errors[field] = `${field} boş bırakılamaz`;
      }
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    const data = new FormData();
    data.append("firstName", formData.firstName);
    data.append("phoneNumber", formData.phoneNumber);
    data.append("email", formData.email);
    data.append("message", formData.message);
    try {
      await axios.post(`${API_BASE_URL}/addKayistMail.php`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setFormData({
        firstName: "",
        phoneNumber: "",
        email: "",
        subject: "",
        message: "",
        privacyPolicy: "",
      });

      toast.success(
        "Formunuz başarıyla alınmıştır ve en kısa sürede incelenecektir",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
      setFormData(initialFormData);
    } catch (error) {
      console.error("Veri yüklenirken hata oluştu:", error);
    }
  };

  return (
    <div className="grid grid-cols-2 gap-4">
       <form onSubmit={handleSubmit}>
        <div className="mb-2 mt-2">
          <label className="mb-1 text-[#6A6969] text-sm font-bold">
            {lang === "tr" ? jsonTr.AdSoyad : jsonEn.AdSoyad}
          </label>
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
            className="w-full px-3 py-2 bg-[#e1ddd4]"
          />
          {formErrors.firstName && (
            <p className="text-red-500 text-sm">
              {lang === "tr"
                ? jsonTr.İsimboşbırakılamaz
                : jsonEn.İsimboşbırakılamaz}
            </p>
          )}
        </div>
        <div className="mb-2">
          <label className="block mb-1 text-[#6A6969] text-sm font-bold">
            {lang === "tr" ? jsonTr.Telefon : jsonEn.Telefon}
          </label>
          <input
            type="text"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleInputChange}
            className="w-full px-3 py-2 bg-[#e1ddd4]"
          />
          {formErrors.phoneNumber && (
            <p className="text-red-500 text-sm">
              {" "}
              {lang === "tr"
                ? jsonTr.Telefonnumarasıboşbırakılamaz
                : jsonEn.Telefonnumarasıboşbırakılamaz}
            </p>
          )}
        </div>
        <div className="mb-2">
          <label className="block mb-1 text-[#6A6969] text-sm font-bold">
            {lang === "tr" ? jsonTr.Eposta : jsonEn.Eposta}
          </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            className="w-full px-3 py-2 bg-[#e1ddd4]"
          />
          {formErrors.email && (
            <p className="text-red-500 text-sm">
              {" "}
              {lang === "tr"
                ? jsonTr.Emailboşbırakılamaz
                : jsonEn.Emailboşbırakılamaz}
            </p>
          )}
        </div>
        <div className="mb-2">
          <label className="block mb-1 text-[#6A6969] text-sm font-bold">
            {lang === "tr" ? jsonTr.Mesaj : jsonEn.Mesaj}
          </label>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            className="w-full px-3 py-4 bg-[#e1ddd4]"
          />
          {formErrors.message && (
            <p className="text-red-500 text-sm">
              {lang === "tr"
                ? jsonTr.Mesajboşbırakılamaz
                : jsonEn.Mesajboşbırakılamaz}
            </p>
          )}
        </div>

        <div className="mt-6 md:mt-2">
          <button
            type="submit"
            className="border-2 border-black-500 bg-[#c3c0b9] px-4 py-1"
          >
            {lang === "tr" ? jsonTr.Gönder : jsonEn.Gönder}
          </button>{" "}
        </div>
      </form>
      <div className="pt-3 ms-4">
        <p style={{display: "flex", gap: "5px"}} className="mb-2">
          <img src="assets/svg/user.svg" alt="User" className="mr-2 w-4 h-4" />
          Sorumlu Kişi: Eda Özkan
        </p>
        <p style={{display: "flex", gap: "5px"}} className="mb-2">
          <img src="assets/svg/mail2.svg" alt="Mail" className="mr-2 w-4 h-4" />
          info@arenilac.com.tr
        </p>
        <p style={{display: "flex", gap: "5px"}}>
          <img src="assets/svg/phone2.svg" alt="Mail" className="mr-2 w-4 h-4" />  
          +90 312 911 51 11
        </p>
      </div>
    </div>
  );
}

export default KayistForm;
