import React, { useState, useEffect } from "react";
import PublicLayout from "../layouts/PublicLayout";
import KayistForm from "../components/Form/KayistForm";

const Kayist = () => {
  return (
    <PublicLayout>
      <div className=" mt-32 md:mt-30 mb-10 w-screen overflow-hidden">
        <img src={window.location.origin + '/assets/img/european-union-logo.jpg'} style={{margin: "auto", width: "200px"}} />

        <div style={{
          backgroundColor: "#2f3d57",
          padding: "8px 0",
          color: "#fff",
          textAlign: "center",
          fontSize: "14px",
          fontWeight: "bold"
        }}>
          <img src={window.location.origin + '/assets/img/kayist-logo-reverse.png'} style={{margin: "auto", width: "100px"}} />
          <p>KAYITLI İSTİHDAM YARATMA PROJESİ</p>
        </div>

        <div style={{ width: "900px", margin: "auto" }}>
          <p style={{ margin: "40px 0", textAlign: "center" }}>
            Firmamız 06.02.2024 tarihinde Kayıtlı Kadın ve mülteci istihdam olanağının sağlanarak üretim kapasitemizin arttırılması için Kalkınma Yatırım Bankası ile sözleşme imzalamıştır. Bu proje kapsamında kadın ve mülteci istihdamına başlamıştır.
          </p>

          <div className="grid grid-cols-3 gap-4">

            <div style={{ backgroundColor: "#2c669a", color: "#fff" }}>
              <img src={window.location.origin + '/assets/img/kayitli-istihdam.jpg'} />
              <div className="p-8">
                <h2 className="text-2xl font-bold mb-3">Kayıtlı İstihdam Yaratma</h2>
                <p className="text-sm">Türk vatandaşları ve mülteciler için istihdam olanaklarını ve çalışma koşullarını iyileştirmek için firmalara mali destek ve teknik yardım sağlanacaktır.</p>
              </div>
            </div>

            <div style={{ backgroundColor: "#3a927c", color: "#fff" }}>
              <img src={window.location.origin + '/assets/img/kadin-kapsayicilik.jpg'} />
              <div className="p-8">
                <h2 className="text-2xl font-bold mb-3">Kadın Kapsayıcı İşletmeler</h2>
                <p className="text-sm">Kadın girişimcilerin ve kadın çalışanları olan firmaların mali destekten yararlanması teşvik edilecek ve bu özellikteki işletmelere öncelik verilecektir.</p>
              </div>
            </div>

            <div style={{ backgroundColor: "#205f72", color: "#fff" }}>
              <img src={window.location.origin + '/assets/img/cevresel-ve-sosyal-taahhut.jpg'} />
              <div className="p-8">
                <h2 className="text-2xl font-bold mb-3">Çevresel ve Sosyal Taahhütler</h2>
                <p className="text-sm">Mali destekten yararlanacak projeler sürdürülebilir kalkınma hedefleri doğrultusunda, çevresel ve sosyal riskleri en aza indirmeye odaklanarak, Dünya Bankası Çevresel ve Sosyal Standartları ile TKYB Çevresel ve Sosyal Yönetim Sistemi çerçevesinde uygulanacaktır.</p>
              </div>
            </div>
          </div>

          <p className="my-9" style={{color: "#223381", fontSize: "24px", fontWeight: "bold"}}>
            Kayıtlı İstihdam Yaratma Projesi (Kayist) Şikayet ve Geri Bildirim Mekanizması
          </p>

          <KayistForm />
        </div>

        
      </div>
    </PublicLayout>
  );
};

export default Kayist;
